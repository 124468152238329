<template>
  <div>
    <v-container fluid> 
      <div class="monitor__container">
        <div class="room__name mb-1"> 
            <v-icon size="40px" color="#2a5298">
              mdi-map-marker
            </v-icon>
            <span class="room">{{ items.farm_name + items.room_name }}</span>
        </div>

        <div class="check__time color_logo">  
          <span>
            측정시간
            <v-icon class="mdi-spin mr-1" color="#3a4f3f">mdi-clock </v-icon>
          </span>
          <!-- 여기수정 -->
          <!-- <span>{{ new Date().toISOString().replace('T', ' ').substring(0, 19) }}</span> -->
          <span>{{ currentTime() }}</span>
        </div>

        <div>
          <span></span>
        </div>

        <div class="sensor__container">
          <div class="sensor__info" v-if="items.temp !== '-'">

            <div class="sensor__name">
              <img :src="temp" class="icon">
              <!-- <v-icon size="30px" color="white">
                mdi-thermometer
              </v-icon> -->
              <span> 온도 </span>

              <div class="dol__name">
                <span>DOL15</span>
              </div>
            </div>

            
            <div class="sensor__value">
              <!-- 최대 최소 집어넣어야함. -->
              <div class="sensor__daily">
                  <span class="color_green"> {{ items.temp.lowest }} </span>
                  <span> | </span>
                  <span class="color_red">{{ items.temp.highest }}</span>
              </div>
              <span :class="{ color_red: items.temp.current > 35, color_green: items.temp.current < 35 }">{{ items.temp.current  }} 
                <span class="unit"> ℃</span>
              </span>
            </div>

            <div class="notice">
              <span> 
                <v-icon size="20px">
                  mdi-information
                </v-icon>
                실시간 날씨 정보
              </span>
              <div> {{ wheather_info.content }} | {{ wheather_info.degree}} ℃</div>

              <span class="check__time">{{ items.temp.chktime }}</span>
            </div>

          </div>

          <div class="sensor__info" v-if="items.hum !== '-'" >
            <div class="sensor__name" >
              <img :src="hum" class="icon">
              <span> 습도 </span>

              <div class="dol__name">
                <span>DOL104</span>
              </div>
            </div>
            <div class="sensor__value">
              <div class="sensor__daily">
                  <span class="color_green"> {{ items.hum.lowest }} </span>
                  <span> | </span>
                  <span class="color_red">{{ items.hum.highest }}</span>
              </div>

              <span :class="{ color_red: items.hum.current > 90, color_green: items.hum.current < 90 }"> 
                {{ items.hum.current }} 
                <span class="unit"> % </span>
              </span>
            </div>

            <div class="notice">
              <span class="check__time">{{ items.hum.chktime }}</span>
            </div>
          </div>

          <div class="sensor__info" v-if="items.co2 !== '-'">
            <div class="sensor__name">
              <img :src="co2" class="icon">
              <span> 이산화탄소 </span>

              <div class="dol__name">
                <span>DOL119</span>
              </div>
            </div>
            <div class="sensor__value2">
              <div class="sensor__daily">
                  <span class="color_green"> {{ items.co2.lowest }} </span>
                  <span> | </span>
                  <span class="color_red">{{ items.co2.highest }}</span>
              </div>

              <span :class="{ color_red: items.co2.current > 4000, color_green: items.co2.current < 4000 }"> 
                {{ items.co2.current }} 
                <span class="unit"> ㏙ </span>
              </span>
            </div>

            <div class="notice">
              <span class="check__time">{{ items.co2.chktime }}</span>
            </div>
          </div>

          <div class="sensor__info" v-if="items.nh3 !== '-'">
            <div class="sensor__name">
              <img :src="nh3" class="icon">
              암모니아

              <div class="dol__name">
                <span>DOL53</span>
              </div>
            </div>
            <div class="sensor__value">
              <div class="sensor__daily">
                  <span class="color_green"> {{ items.nh3.lowest }} </span>
                  <span> | </span>
                  <span class="color_red">{{ items.nh3.highest }}</span>
              </div>

              <span :class="{ color_red: items.nh3.current >= 1, color_green: items.nh3.current < 1  }"> 
                {{ items.nh3.current }} 
                <span class="unit"> ㏙ </span>
              </span>
              
            </div>

            <div class="notice">
              <div v-show="items.nh3.current >= 1">
                <span> 
                  <v-icon size="20px">
                    mdi-information
                  </v-icon>
                  암모니아 배출기준 치를 초과했습니다. 
                </span>
                <div> 배출허용기준 : 암모니아 1ppm 이하 </div>
              </div>

              <span class="check__time">{{ items.nh3.chktime }}</span>
            </div>
          </div>

          <div class="sensor__info" v-if="items.h2s !== '-'">
            <div class="sensor__name" >
              <img :src="h2s" class="icon">

              황화수소
              <div class="dol__name">
                <span>SI-100C</span>
              </div>
            </div>
            <div class="sensor__value">
              <div class="sensor__daily">
                  <span class="color_green"> {{ items.h2s.lowest }} </span>
                  <span> | </span>
                  <span class="color_red">{{ items.h2s.highest }}</span>
              </div>

              <span :class="{ color_red: items.h2s.current >= 0.02,color_green: items.h2s.current < 0.02 }">
                {{ items.h2s.current }}
                <span class="unit"> ㏙ </span>
              </span>
            </div>

            <div class="notice">
              <div v-show="items.h2s.current >= 0.02">
                <span>
                  <v-icon size="20px">
                    mdi-information
                  </v-icon>
                  황화수소 배출기준 치를 초과했습니다. 
                </span>
                <div> 배출허용기준 : 황화수소 0.02ppm 이하 </div>
              </div>

              <span class="check__time">{{ items.h2s.chktime }}</span>
            </div>
          </div>
        </div>
      </div>

    </v-container>
  </div>
</template>

<script>
// import VueCookies from "vue-cookies";
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";

var setTime = 0;
var setTimeWeather = 0;

export default {
  name: 'RoomMonitor',

  data: () => ({
    temp: require("@/assets/value/temp.svg"),
    hum: require("@/assets/value/humidity.svg"),
    nh3: require("@/assets/value/nh3.svg"),
    h2s: require("@/assets/value/h2s.svg"),
    co2: require("@/assets/value/co2.svg"),

    items:{temp: {},hum:{},co2:{},nh3:{},h2s:{},},
    wheather_info: { },
    // currentTime: '',
  }),
  computed: {
    // currentTime() {
    //   return dateUtil.format(new Date, "yyyy-MM-dd HH:mm:ss");
    // }
  },
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    if (this.$route.params.room) { 
      this.refreshList();
      this.refreshWeatherInfo();
    } else {
      // 새로고침 또는 비정상경로 접근시 처리
      this.$router.go(-1);
    }

  },
  destroyed() {
    clearTimeout(setTime);
    clearTimeout(setTimeWeather);
  },
  methods: {
    currentTime() {
      return dateUtil.format(new Date, "yyyy-MM-dd HH:mm:ss");
    },
    refreshList() {
      this.$store.commit("resMessage","");
    
      Apis.roomMonitoringSummary({
        room_cd : this.$route.params.room.code,
        stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"),
      } ,(res) => {  // 정상처리
        if (!res.result)  this.$store.commit("resMessage",res.message);
      
        this.items = res.data;

        this.$route.path == "/monitoring/roommonitor" ? setTime = setTimeout( () =>  this.refreshList(), 20 * 1000) : "" ;

      }).catch( (err) => {  // API 오류 처리
        this.$store.commit("resMessage",err);
        console.log("roomMonitoringSummary 호출 오류",err);
      });

    },

    refreshWeatherInfo() {
      Apis.monitoringWeather({
        farm_cd: this.$route.params.farm.code,
      } ,(res) => {
        // NOTE: 해당 농장/사업장정보에 등록된 위치정보 기반 날씨 정보
        this.wheather_info = res.data;

        this.$route.path == "/monitoring/roommonitor" ? setTimeWeather = setTimeout( () =>  this.refreshWeatherInfo(), 30 * 60 * 1000) : "" ;

      }).catch( (err) => {  // API 오류 처리
        this.$store.commit("resMessage",err);
        console.log("refreshWeatherInfo 호출 오류",err);
      });
    },

  },
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: 'GmarketSansMedium';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    transform : rotate(0.04deg);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'GmarketSansMedium';
  transform : rotate(0.04deg);
}
.monitor__container{
  display:flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
  background-color:#f9f9fc;
}
.room__name{
  text-align:center;
  font-size:1.8vw;
  margin-top:20px;
  letter-spacing: -1.5px;
  color:#3a4f3a;
}
.check__time{
  text-align:center;
  font-size:0.8vw;
  color:#cccccc;
}
.sensor__container{
  display:flex;
  justify-content: center;
  padding:5px;
  margin-top:10px;
}

.sensor__info{
  display:flex;
  flex-direction: column;
  width:20%;
  height:80vh;
  margin:10px;
  text-align:center;
  border-radius:10px;
  // border:1px solid red;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #fafafa 100%);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
}
.room{
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  padding:5px;
  border-radius:10px;
  color:#2a5298;
}
.sensor__name{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:3.0vw;
  letter-spacing: -1px;
  height:30%;
  background-image: linear-gradient(to top, #4c6852 0%, #9bab9f 100%);
  color:white;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.3);
}
.dol__name{
  display:inline-block;
  font-size:1.5vw;
  padding:5px;
  // margin-top:5px;
  border-radius:25px;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #fafafa 100%);
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.3);
  color:#4c6852;
}
.sensor__daily{
  font-size:1.3vw;
  letter-spacing: -0.74px;
}
.sensor__value{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:70%;
  font-size:5vw;
  letter-spacing: -4px;
}
.sensor__value2{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height:70%;
  font-size:4.6vw;
  letter-spacing: -5px;
}
.unit{
  font-size:25px;
  margin-left:5px;
  margin-right:5px;
}
.notice{
  // background-color:red;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height:8vh;
  font-size:1.0vw;
  letter-spacing: -1px;
  color:gray;
}
.color_red{
  color:#99191a;
}
.color_green{
  color:#2a5298;
}

.color_logo{
  color:#3a4f3a;
}

.icon{
  width:35px;
}


</style>
